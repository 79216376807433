import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

import { ModuleItem } from './ModuleItem';

type Data = {
  modules: {
    nodes: {
      title: string
      list: string[]
      thumb: ImageDataLike
      slug: string
    }[]
  }
};

function Modules() {
  const { modules } = useStaticQuery<Data>(graphql`
      query Modules {
          modules: allModulesJson(sort: {fields: order, order: ASC}) {
              nodes {
                  order
                  title
                  list
                  slug
                  id
                  thumb {
                      childImageSharp {
                          gatsbyImageData(
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              quality: 100
                              height: 240
                          )
                      }
                  }
              }
          }
      }
  `);

  return (
    <Container
      fluid="lg"
      className="section"
    >
      <h2>
        <Trans>modules-header</Trans>
      </h2>
      <Row>
        {
          modules.nodes.map(({
            thumb, title, list, slug,
          }) => (
            <Col
              xs={6}
              md={4}
              lg={3}
              className="p-2"
              key={slug}
            >
              <ModuleItem
                list={list}
                slug={slug}
                title={title}
                thumb={thumb}
              />
            </Col>
          ))
        }
      </Row>
    </Container>
  );
}

export {
  Modules,
};
