import React, { PropsWithChildren } from 'react';
import { IFluidObject } from 'gatsby-background-image';

import { Navigation } from './navigation';
import { Seo } from './Seo';
import { Footer } from './footer';

import * as styles from './style.module.scss';

type Props = PropsWithChildren<{
  footerImage: IFluidObject
}>;

function Layout({ children, footerImage }: Props) {
  return (
    <>
      <Seo />
      <Navigation />
      <main className={styles.layoutContent}>
        {children}
      </main>
      <Footer bgImage={footerImage} />
    </>
  );
}

export {
  Layout,
};
