import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import { Step } from './Step';
import * as styles from './style.module.scss';

function Workflow() {
  const { t } = useTranslation();

  return (
    <Container
      fluid="lg"
      className="section"
    >
      <Row>
        <Col xs="12">
          <h2 className="text-center">
            <Trans>workflow-main-header</Trans>
          </h2>
        </Col>
      </Row>
      <Step
        header={t('workflow-analysis-header')}
        body={(
          <p>
            {t('workflow-analysis-body')}
          </p>
        )}
      >
        <StaticImage
          className={styles.image}
          src="../../images/analysis_thumb.jpg"
          alt="Analysis"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Step>
      <Step
        header={t('workflow-solution-header')}
        body={(
          <p>
            {t('workflow-solution-body')}
          </p>
        )}
        isOdd
      >
        <StaticImage
          className={styles.image}
          src="../../images/solution_thumb.jpg"
          alt="Solution"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Step>
      <Step
        header={t('workflow-implementation-header')}
        body={(
          <p>
            {t('workflow-implementation-body')}
          </p>
        )}
      >
        <StaticImage
          className={styles.image}
          src="../../images/implementation_thumb.jpeg"
          alt="Implementation"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Step>
      <Step
        header={t('workflow-support-header')}
        body={(
          <p>
            {t('workflow-support-body')}
          </p>
        )}
        isOdd
      >
        <StaticImage
          className={styles.image}
          src="../../images/support_thumb.jpg"
          alt="Support"
          placeholder="blurred"
          layout="fullWidth"
        />
      </Step>
    </Container>
  );
}

export {
  Workflow,
};
