import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import classnames from 'classnames';
import * as styles from './styles.module.scss';

type Props = {
  onClick: (e: React.MouseEvent) => void,
  language: string
};

const LanguageToggle = React.forwardRef<HTMLDivElement, Props>(
  ({ onClick, language }, ref) => {
    const { t } = useTranslation();

    return (
      <div
        className={styles.toggle}
        title={t('language')}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <span className={classnames('me-2', styles.langIcon)}>
          &#127760;
        </span>
        {language.toUpperCase()}
      </div>
    );
  },
);

export {
  LanguageToggle,
};
