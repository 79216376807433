import React from 'react';
import { Helmet } from 'react-helmet';

function Seo() {
  const seo = {
    title: 'Didah.net',
    description: 'Our team develops and implements the tools of planning and business management for over 12 years.',
    url: 'https://didah.net',
  };

  return (
    <Helmet title={seo.title}>
      <meta
        name="description"
        content={seo.description}
      />
      {seo.url && (
        <meta
          property="og:url"
          content={seo.url}
        />
      )}
      {seo.title && (
        <meta
          property="og:title"
          content={seo.title}
        />
      )}
      {seo.description && (
        <meta
          property="og:description"
          content={seo.description}
        />
      )}
      {seo.title && (
        <meta
          name="twitter:title"
          content={seo.title}
        />
      )}
      {seo.description && (
        <meta
          name="twitter:description"
          content={seo.description}
        />
      )}
    </Helmet>
  );
}

export {
  Seo,
};
