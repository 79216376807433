import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { IFluidObject } from 'gatsby-background-image';

import { Layout } from 'components/layout';
import { Intro } from 'components/intro';
import { About } from 'components/about';
import { Modules } from 'components/modules';
import { Workflow } from 'components/workflow';
import { Contact } from 'components/contact';

type Data = {
  footer: {
    childImageSharp: {
      fluid: IFluidObject
    }
  }
};

// markup
function IndexPage({ data }: PageProps<Data>) {
  const footerImage = data.footer.childImageSharp.fluid;

  return (
    <Layout footerImage={footerImage}>
      <Intro />
      <About />
      <Modules />
      <Workflow />
      <Contact />
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        footer: file(relativePath: { eq: "footer.jpg" }) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
