import React from 'react';
import { Button, Card } from 'react-bootstrap';
import {
  Trans, useTranslation, Link,
} from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import classnames from 'classnames';

import * as styles from './style.module.scss';

type Props = {
  title: string
  list: string[]
  slug: string
  thumb: ImageDataLike
};

function ModuleItem({
  list, slug, thumb, title,
}: Props) {
  const { t } = useTranslation();
  const image = getImage(thumb);

  const card = (
    <Card>
      {
        image && (
          <Link to={`/modules/${slug}`}>
            <GatsbyImage
              className={classnames('card-img-top', styles.cardImg)}
              image={image}
              alt={title}
              style={{ height: '240px' }}
            />
          </Link>
        )
      }
    </Card>
  );

  return (
    <div
      className={classnames(
        'p-md-3 d-flex flex-column justify-content-between',
        styles.moduleItem,
      )}
    >
      <div>
        {card}
        <h5 className="px-sm-3 mt-1">
          {t(title)}
        </h5>
        <ul>
          {
            list.map((line) => (
              <li key={line}>
                {t(line)}
              </li>
            ))
          }
        </ul>
      </div>
      <Link
        to={`/modules/${slug}`}
        className="d-inline-block mx-auto"
      >
        <Button
          variant="outline-primary"
          type="button"
        >
          <Trans>modules-go-to-module</Trans>
        </Button>
      </Link>
    </div>
  );
}

export {
  ModuleItem,
};
