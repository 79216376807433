/* eslint-disable max-len */
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import classnames from 'classnames';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import {
  Button,
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import * as styles from './style.module.scss';

function Intro() {
  const data = useStaticQuery(
    graphql`
        query {
          desktop: file(relativePath: { eq: "intro.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `,
  );
  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid;
  return (
    <BackgroundImage
      critical
      className={classnames(
        styles.intro,
        'text-white d-flex align-items-center',
      )}
      Tag="section"
      fluid={imageData}
    >
      <Container fluid="lg">
        <Row>
          <Col lg="6">
            <h1 className={classnames('mb-4 mt-5', styles.introHeading)}>
              <Trans>intro</Trans>
            </h1>
            <div>
              <Link to="#contact">
                <Button
                  type="button"
                  size="lg"
                >
                  <Trans>header-contact</Trans>
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
}

export {
  Intro,
};
