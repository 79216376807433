import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { LanguageToggle } from './LanguageToggle';

import * as styles from './styles.module.scss';

function LanguagePicker() {
  const { changeLanguage, languages, language } = useI18next();

  return (
    <Dropdown
      id="navbarScrollingDropdown"
      onSelect={(key) => key && changeLanguage(key)}
    >
      <Dropdown.Toggle
        language={language}
        as={LanguageToggle}
        id="dropdown-custom-components"
      />
      <Dropdown.Menu
        align="end"
        className={styles.menu}
      >
        {
          languages.map((lng) => (
            <Dropdown.Item
              key={lng}
              eventKey={lng}
            >
              {lng.toUpperCase()}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

export {
  LanguagePicker,
};
