import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';

import { LanguagePicker } from './components/language-picker';
import * as styles from './style.module.scss';

function Navigation() {
  return (
    <header className={styles.header}>
      <Navbar
        collapseOnSelect
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <StaticImage
                className={styles.logo}
                src="../../../images/logo_new.png"
                alt="Didah"
              />
            </Link>
          </Navbar.Brand>
          <LanguagePicker />
        </Container>
      </Navbar>
    </header>
  );
}

export {
  Navigation,
};
